import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import SiteRouter from './site-router';
import 'react-toastify/dist/ReactToastify.css';
import './fonts/Sting-Black.892a407c.woff2';
import './fonts/Sting-Regular.36cc7c9c.woff2';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SiteRouter />
  </React.StrictMode>
);


